body {
    margin: 0;
    padding: 0;
    font-family: 'Lora', serif;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 400;
    min-height: 100vh;
}

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.App {
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App::before {
    content: '';
    position: fixed;
    z-index: -1;
    display: block;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-image: url('../public/back-image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: inherit;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    margin: 10px;
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5);
}

.language-select {
    background-color: #ff0000;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 100;
    margin: 20px 20px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}


.language-select:focus {
    outline: none;
}

.card {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 900px;
    width: 80%;
    margin: 20px 10px;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(10px);
    border: white 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    color: ivory;
    display: inline-block;
}

.title:after {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    background-color: #ff0000;
    margin: 0px auto;
}

.icon::after {
    content: '👨🏼‍🍳';
    display: block;
    width: 100px;
    height: 5px;
    background-color: #ff0000;
    margin: 0px auto;
    margin-bottom: 40px;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: 20px;
    margin-top: 20px;
    color: ivory;
    text-decoration: underline;
}

.subtitle::before {
    content: '';
    display: block;
    max-width: 400px;
    height: 2px;
    background-color: ivory;
    margin: 5px auto;
}

.no-line::before {
    display: none;
}

.no-marker::marker {
    content: none;
}

.description {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 20px;
    color: ivory;
    text-align: justify;
    padding: 0 25px;
}

.align-left {
    text-align: start;
}

.ingredients-input {
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 100;
    margin: 10px 25px;
    color: #ff0000;
    padding: 10px;
    min-width: 278px;
    width: 70%;
}

.ingredients-input:focus {
    outline: 2px solid #ff0000;
}

.ingredients-input::placeholder {
    color: #164043;
}

.search-btn {
    background-color: #ff0000;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 100;
    margin: 10px 25px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    min-width: 35%;
}

.search-btn:hover {
    background-color: #c60000;
    transition: 0.4s;
}

.search-btn:disabled {
    background-color: #ff6d6d;
    color: #fff;
    cursor: not-allowed;
}

.recipe {
    margin: 20px 0;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.5);
    resize: none;
    text-align: start;
    color: #164043;
}

.developer-avatar-container a {
    display: block;
    text-decoration: none;
    color: #fff;
}

.developer-avatar {
    width: 100px;
    height: 100px;
    border:ivory 1px solid;
    border-radius: 50%;
    margin: 0 auto;
}

.status {
    font-size: 1.2rem;
    font-weight: 100;
    margin: 20px 20px;
    color: ivory;
}

.error {
    color: red;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #ff0000;
}

h1 {
    margin: 5px;
}

summary {
    cursor: pointer;
    animation: close 0.5s ease;
}

summary::marker {
    color: ivory;
}

summary::before {
    content: '👨🏼‍🍳';
}

.no-icon::before {
    content: '';
}

details[open] {
    animation: open 0.5s ease;
}

.typewriter {
    font-weight: bold;
    color: #ff0000;
  }

.text-border {
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

@keyframes open {
    0% {
        max-height: 60px;
        overflow: hidden;
    }
    100% {
        max-height: 900px;
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: 1.4rem;
    }

    .subtitle {
        font-size: 1.3rem;
    }

    .description {
        font-size: 1.3rem;
        text-align: center;
    }

    .recipe {
        font-size: 1rem;
        padding: 20px;
    }

    .align-left {
        text-align: start;
        padding: 10px;
    }

    .card {
        align-items: unset;
    }

    .ingredients-input {
        width: unset;
        min-width: unset;
    }

    .search-btn {
        min-width: unset;
    }
}